/*
 * @Description:
 * @Author: ChenXueLin
 * @Date: 2022-04-27 16:30:36
 * @LastEditTime: 2022-07-15 16:47:12
 * @LastEditors: ChenXueLin
 */
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import { printError } from "@/utils/util";
import {
  findDownList,
  getListIdAndNames,
  getCompanydownList,
  getCorpAttrDownList
} from "@/api";
export default {
  data() {
    return {
      dialogLoading: false,
      total: 0,
      returnVisitTypeList: [], //回访状态下拉
      dialogData: [], //弹框列表数据
      selectData: [], //选中的数据
      corpList: [], //客户名称
      employeeList: [], //业务员列表
      corpAttrEnum: [], //客户属性列表
      corpClassEnum: [], //客户分级列表
      companyEnum: [], //所属主体列表
      revisitStatusList: [] //回访状态下拉框
    };
  },
  mixins: [base, listPage],
  props: ["visible"],
  computed: {
    addItemVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.updatevisible(v);
      }
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.searchForm.issuccess = "4";
          this.initData();
          this.queryList();
        }
      }
    }
  },
  methods: {
    // 初始化数据
    async initData() {
      try {
        let promiseList = [
          findDownList(["RevisitStatus", "returnVisitType", "corpClass"]),
          getListIdAndNames({
            id: 4
          }),
          getCorpAttrDownList({}),
          getCompanydownList({ isManageEmp: false, companyId: "" })
        ];
        let [res, employeeRes, attrRes, companyEnumRes] = await Promise.all(
          promiseList
        );
        //回访状态下拉框
        this.revisitStatusList = this.getFreezeResponse(res, {
          path: "data.RevisitStatus"
        });
        //客户分级
        this.corpClassEnum = this.getFreezeResponse(res, {
          path: "data.corpClass"
        });
        //业务员
        this.employeeList = this.getFreezeResponse(employeeRes, {
          path: "data"
        });
        //客户属性
        this.corpAttrEnum = this.getFreezeResponse(attrRes, {
          path: "data"
        });
        //所属主体
        this.companyEnum = this.getFreezeResponse(companyEnumRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //选中数据
    handleSelection(val) {
      this.selectData = val;
    },
    //点击确定
    pushData(type) {
      console.log(type, "type==");
      let selectData = this.selectData;
      if (!selectData.length) {
        this.$message.warning("请选择任务项");
        return;
      }
      if (type == "maintenance") {
        selectData.map(item => {
          item.revisitedManNumber = item.linkRpNumber;
          item.revisitedManName = item.linkRpName;
        });
      }
      if (selectData.length) {
        this.$emit("pushData", selectData);
      }
      this.selectData = [];
      this.$refs.multipleTable.clearSelection();
      this.closeDialog();
    },
    // 关闭弹框
    closeDialog() {
      this.dialogData = [];
      this.updatevisible(false);
    },
    updatevisible(v) {
      this.$emit("update:visible", v);
    }
  }
};
